import { Button } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

import Scrollbars from "react-custom-scrollbars-2";
import { useDispatch } from "react-redux";
import {
  resetAllMode,
  setDataLayout,
} from "../../data/redux/themeSettingSlice";
import { setExpandMenu } from "../../data/redux/sidebarSlice";
import usePreviousRoute from "../usePreviousRoute";
import axios from "axios";
import {
  IconSchool,
  IconDashboard,
  IconBrandAsana,
  IconBrandThreads,
  IconCategoryFilled,
  IconUsersGroup,
  IconZodiacAries,
  IconHomeEco,
  IconTableOptions,
  IconSitemap,
  IconBoxMultiple2,
  IconSettings,
  IconMessageUser,
  IconHeartPlus,
  IconChartBarPopular,
  IconFileSpreadsheet,
  IconGift,
  IconCopyCheck,
  IconCoins,
  IconFilePlus,
} from "@tabler/icons-react";

const iconComponents = {
  IconDashboard: IconDashboard,
  IconSchool: IconSchool,
  IconMessageUser: IconMessageUser,
  IconBrandAsana: IconBrandAsana,
  IconBrandThreads: IconBrandThreads,
  IconCategoryFilled: IconCategoryFilled,
  IconTableOptions: IconTableOptions,
  IconUsersGroup: IconUsersGroup,
  IconZodiacAries: IconZodiacAries,
  IconHomeEco: IconHomeEco,
  IconSitemap: IconSitemap,
  IconBoxMultiple2: IconBoxMultiple2,
  IconSettings: IconSettings,
  IconHeartPlus: IconHeartPlus,
  IconChartBarPopular: IconChartBarPopular,
  IconFileSpreadsheet: IconFileSpreadsheet,
  IconGift: IconGift,
  IconCopyCheck: IconCopyCheck,
  IconCoins: IconCoins,
  IconFilePlus: IconFilePlus,
};
const navigationSchool = [
  {
    title: "Dashboard",
    href: "/school/dashboard",
    icon: "IconDashboard",
  },
  {
    title: "Manage Students",
    href: "/school/manageStudent",
    icon: "IconSchool",
  },

  {
    title: "Manage Teachers",
    icon: "IconBrandAsana",
    subMenu: [
      {
        title: "Teachers",
        href: "/school/teachers",
        icon: "IconBrandThreads",
      },
      {
        title: "Teachers Type",
        href: "/school/teachers/type",
        icon: "IconCategoryFilled",
      },
    ],
  },
  {
    title: "Manage Aadats",
    icon: "IconBrandAsana",
    subMenu: [
      {
        title: "Aadats",
        href: "/school/aadats",
        icon: "IconBrandThreads",
      },
      {
        title: "Categories",
        href: "/school/categories",
        icon: "IconCategoryFilled",
      },
      {
        title: "Miqaat",
        href: "/school/miqaat",
        icon: "IconTableOptions",
      },
    ],
  },

  {
    title: "Manage Masters",
    icon: "IconUsersGroup",
    subMenu: [
      {
        title: "Allergies",
        href: "/school/allergies",
        icon: "IconZodiacAries",
      },
      {
        title: "House",
        href: "/school/house",
        icon: "IconHomeEco",
      },
      {
        title: "Term",
        href: "/school/term",
        icon: "IconSitemap",
      },
      {
        title: "Section",
        href: "/school/grade/",
        icon: "IconBoxMultiple2",
      },
    ],
  },

  {
    title: "Behaviour",
    icon: "IconHeartPlus",
    subMenu: [
      {
        title: "Assign Points",
        href: "/school/points",
        icon: "IconFilePlus",
      },
      {
        title: "LeaderBoard",
        href: "/school/behaviour/leaderboard",
        icon: "IconChartBarPopular",
      },
      {
        title: "Points",
        href: "/school/behaviour/points",
        icon: "IconCoins",
      },
      {
        title: "Categories",
        href: "/school/behaviour/categories",
        icon: "IconCategoryFilled",
      },
      {
        title: "Coupon",
        href: "/school/behaviour/Coupons/list",
        icon: "IconGift",
      },
      {
        title: "Coupon Approval",
        href: "/school/behaviour/Coupons/request",
        icon: "IconCopyCheck",
      },
      {
        title: "Report",
        href: "/school/behaviour/report",
        icon: "IconFileSpreadsheet",
      },
    ],
  },


  {
    title: "Manage Attendance",
    icon: "IconBrandAsana",
    subMenu: [
      {
        title: "Day Tag Listing",
        href: "/attendance",
        icon: "IconBrandThreads",
      },
      {
        title: "Class Attendance",
        href: "/classattendance",
        icon: "IconCategoryFilled",
      },
      {
        title: "Day Manual Scan",
        href: "/dayattendancemanualscan",
        icon: "IconCategoryFilled",
      },
      {
        title: "Day Summary",
        href: "/Dayattendancesummary",
        icon: "IconTableOptions",
      },
      {
        title: "Attendance Details",
        href: "/DayAttendanceDetails",
        icon: "IconTableOptions",
      },
      {
        title: "Teacher’s  Day Attendance",
        href: "/Teacher’sdayattendance",
        icon: "IconTableOptions",
      },

      {
        title: "Certificate List",
        href: "/Certificatelist",
        icon: "IconTableOptions",
      },
      {
        title: "Teacher Leave Request",
        href: "/leave/request/list/student",
        icon: "IconBrandThreads",
      },
      {
        title: "Student Leave Request",
        href: "/leave/request/list/teacher",
        icon: "IconBrandThreads",
      },
    ],
  },


  {
    title: "Settings",
    href: "/school/settings",
    icon: "IconSettings",
  },
];

const SchoolSidebar = ({ setSchoolDetails }) => {
  const showMobilemenu = () => {
    document.getElementById("sidebarArea").classList.toggle("showSidebar");
  };

  const [user, setUsers] = useState("");
  const [module, setModule] = useState([]);
  useEffect(() => {
    setUsers(localStorage.getItem("user"));
  }, []);

  const Location = useLocation();
  const currentPath = Location.pathname;

  const [subOpen, setSubopen] = useState("");
  const [subsidebar, setSubsidebar] = useState("");

  const toggleSidebar = (title) => {
    localStorage.setItem("menuOpened", title);
    if (title === subOpen) {
      setSubopen("");
    } else {
      setSubopen(title);
    }
  };

  const toggleSubsidebar = (subitem) => {
    if (subitem === subsidebar) {
      setSubsidebar("");
    } else {
      setSubsidebar(subitem);
    }
  };

  const handleLayoutChange = (layout) => {
    dispatch(setDataLayout(layout));
  };

  const handleClick = (label, themeSetting, layout) => {
    toggleSidebar(label);
    if (themeSetting) {
      handleLayoutChange(layout);
    }
  };

  const getLayoutClass = (label) => {
    switch (label) {
      case "Default":
        return "default_layout";
      case "Mini":
        return "mini_layout";
      case "Box":
        return "boxed_layout";
      case "Dark":
        return "dark_data_theme";
      case "RTL":
        return "rtl";
      default:
        return "";
    }
  };
  const location = useLocation();
  const dispatch = useDispatch();
  const previousLocation = usePreviousRoute();

  useEffect(() => {
    const layoutPages = [
      "/layout-dark",
      "/layout-rtl",
      "/layout-mini",
      "/layout-box",
      "/layout-default",
    ];

    const isCurrentLayoutPage = layoutPages.some((path) =>
      location.pathname.includes(path)
    );
    const isPreviousLayoutPage =
      previousLocation &&
      layoutPages.some((path) => previousLocation.pathname.includes(path));

    if (isPreviousLayoutPage && !isCurrentLayoutPage) {
      dispatch(resetAllMode());
    }
  }, [location, previousLocation, dispatch]);

  useEffect(() => {
    setSubopen(localStorage.getItem("menuOpened"));
    // Select all 'submenu' elements
    const submenus = document.querySelectorAll(".submenu");
    // Loop through each 'submenu'
    submenus.forEach((submenu) => {
      // Find all 'li' elements within the 'submenu'
      const listItems = submenu.querySelectorAll("li");
      submenu.classList.remove("active");
      // Check if any 'li' has the 'active' class
      listItems.forEach((item) => {
        if (item.classList.contains("active")) {
          // Add 'active' class to the 'submenu'
          submenu.classList.add("active");
          return;
        }
      });
    });
  }, [Location.pathname]);

  // const onMouseEnter = () => {
  //   dispatch(setExpandMenu(true));
  // };
  // const onMouseLeave = () => {
  //   dispatch(setExpandMenu(false));
  // };

  useEffect(() => {
    const userInhead = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_DEV_BASE_URL}/api/v1/school/school-admin`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );

        setModule(res.data?.data?.school?.modulesActivated);
        setSchoolDetails({
          schoolName: res.data?.data?.school?.schoolName,
          schoolLogo: res.data?.data?.school?.schoolLogo,
          fullName: `${res.data?.data?.userData?.firstName} ${res.data?.data?.userData?.lastName}`,
        });
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
    userInhead();
  }, []);

  const checkPermissions = (title) => {
    if (title === "Manage Students") {
      return (
        module?.some(
          (mod) => mod.moduleId.moduleName === "Student" && mod.status === true // Check status too
        ) || false
      ); // Return false if module is undefined or no match
    }
    if (title === "Manage Teachers") {
      return (
        module?.some(
          (mod) => mod.moduleId.moduleName === "Teacher" && mod.status === true // Corrected spelling
        ) || false
      ); // Return false if module is undefined or no match
    }
    if (title === "Manage Aadats") {
      return (
        module?.some(
          (mod) => mod.moduleId.moduleName === "Aadat" && mod.status === true // Check status too
        ) || false
      ); // Return false if module is undefined or no match
    }

    if (title === "Behaviour") {
      return (
        module?.some(
          (mod) =>
            mod.moduleId.moduleName === "Behaviour" && mod.status === true // Check status too
        ) || false
      ); // Return false if module is undefined or no match
    }

    return true; // Default case if title does not match any
  };

  const [dropdownStates, setDropdownStates] = useState({});
  const [activeDropdownIndex, setActiveDropdownIndex] = useState(null);
  const sidebarRef = useRef(null);
  const handleDropdownToggle = (index) => {
    setDropdownStates((prevState) => ({
      // Close the previously opened submenu, open the new one
      [index]: prevState[index] ? false : true,
    }));
  };

  // Close all dropdowns when route changes
  useEffect(() => {
    setDropdownStates({});
  }, [location]);
  const closeAllDropdowns = () => {
    setDropdownStates({});
  };
  const handleOutsideClick = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      closeAllDropdowns();
      console.log("dddkapil");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  return (
    <div className="sidebar" id="sidebar" ref={sidebarRef}>
      <Scrollbars>
        <div className="sidebar-inner slimscroll">
          <div id="sidebar-menu" className="sidebar-menu">
            <ul className="navigation-menu p-0">
              {navigationSchool.map((navi, index) => {
                // Check permission for the current navigation item
                if (!checkPermissions(navi.title)) {
                  return null; // Hide the menu item if permission is denied
                }

                const IconComponent = iconComponents[navi.icon]; // Get the icon component dynamically

                return (
                  <li
                    key={index}
                    className={`menu-item d-flex ${
                      navi.subMenu ? "has-dropdown" : ""
                    } ${
                      currentPath === navi.href ||
                      (navi.subMenu &&
                        navi.subMenu.some(
                          (subItem) => currentPath === subItem.href
                        ))
                        ? "active-path"
                        : ""
                    }`}
                  >
                    {navi.subMenu ? (
                      <>
                        <a
                          onClick={() => handleDropdownToggle(index)}
                          className="dropdown-toggle"
                        >
                          <div className="iconCont">
                            {IconComponent && <IconComponent stroke={1.5} />}{" "}
                            {/* Render icon dynamically */}
                          </div>
                          <span>{navi.title}</span>
                        </a>
                        {dropdownStates[index] && (
                          <ul className="dropdown-menu">
                            {navi.subMenu.map((subItem, subIndex) => {
                              const SubIconComponent =
                                iconComponents[subItem.icon];
                              // Check permission for the submenu item
                              if (!checkPermissions(subItem.title)) {
                                return null; // Hide the submenu item if permission is denied
                              }
                              return (
                                <li
                                  key={subIndex}
                                  className={`${
                                    currentPath === subItem.href
                                      ? "active-path"
                                      : ""
                                  }`}
                                >
                                  <Link
                                    to={subItem.href}
                                    onClick={() => setDropdownStates({})}
                                  >
                                    {SubIconComponent && (
                                      <SubIconComponent stroke={1.5} />
                                    )}{" "}
                                    {/* Submenu icon */}
                                    <span>{subItem.title}</span>
                                  </Link>
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </>
                    ) : (
                      <Link to={navi.href}>
                        <div className="iconCont">
                          {IconComponent && <IconComponent stroke={1.5} />}{" "}
                          {/* Render icon dynamically */}
                        </div>
                        <span>{navi.title}</span>
                      </Link>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </Scrollbars>
    </div>
  );
};

export default SchoolSidebar;
