import React, { useEffect, useState, useRef } from "react";
import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormSelect,
  CRow,
  CContainer,
  CFormTextarea,
} from "@coreui/react";
// imports for modal
import "../Attendance.css"
import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
} from "@coreui/react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import {  TimePicker } from "antd";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import axios from "axios";
import { Button, Checkbox, Table, Space, notification } from "antd";

import Select from "react-select";
import { useLocation, useNavigate } from "react-router-dom";
import { useAssignContext } from "../../../../helpers/assignContext";

function MarkAttendance() {
  const navigate = useNavigate();
  const token = localStorage.getItem("accessToken");

  const { appState, setAppState } = useAssignContext();
  const [dateRange, setdateRange] = useState();
    // const [startDate, endDate] = dateRange;
    const [startDate , setStartDate] = useState(null)
  const [selectAll, setSelectAll] = useState(false);
  const [collecIds, setCollecIds] = useState([]);
  const [formData, setFormData] = useState({
    date: new Date(),
    eventType: "",
    leaveType: "",
    remark: "",
  });
  const [subject, setSubject] = useState("");

  const [validated, setValidated] = useState(false);
  const [visible, setVisible] = useState(false);
  const [userList, setUserList] = useState([]);

  const [selectedUsers, setSelectedUsers] = useState();

  const [userDetails, setUserDetails] = useState([]);

  const [date, setData] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() == false) {
      setValidated(true);
    } else {
      setVisible(true);
    }
  };

  const userOption = userList?.map((value) => ({
    value: value?._id,
    label: `${value?.firstName} ${value?.lastName} / ${value?.itsNo} `,
  }));

  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    const updatedStudents = selectedUsers.map((student) => ({
      ...student,
      selected: !selectAll,
    }));
    setSelectedUsers(updatedStudents);
    setCollecIds(
      !selectAll ? updatedStudents.map((student) => student._id) : []
    );
  };

  const handleSelect = (id) => {
    const updatedStudents = selectedUsers.map((student) => {
      if (student._id === id) {
        student.selected = !student.selected;
        if (student.selected) {
          setCollecIds((prev) => [...prev, id]);
        } else {
          setCollecIds((prev) => prev.filter((studentId) => studentId !== id));
        }
      }
      return student;
    });
    setSelectedUsers(updatedStudents);
  };
  const handleDataChange = (value) => setData(value);
  const handleStartTimeChange = (value) => setStartTime(value);
  const handleEndTimeChange = (value) => setEndTime(value);
  const columns = [
    {
      title: "S.No.",
      dataIndex: "index",
          key: "index",
      width:0,
      render: (_, __, index) => index + 1, // Calculates serial number
    },
    {
        title: "Student Name",
        width:100,
      dataIndex: "name",
    },
    {
      title: "Check",
        dataIndex: "selected",
        width:50,
      render: (_, record) => (
        <Checkbox
          checked={record?.selected}
          onChange={() => handleSelect(record?._id)}
        />
      ),
    },
  ];
  return (
    <>
      <CContainer fluid className="container overflow-auto">
        {/* modal */}
        <CModal
          backdrop="static"
          visible={visible}
          onClose={() => setVisible(false)}
          aria-labelledby="StaticBackdropExampleLabel"
        >
          <CModalHeader>
            <CModalTitle id="StaticBackdropExampleLabel">
              Form Submission
            </CModalTitle>
          </CModalHeader>
          <CModalBody className="pb-3">
            Are you sure you wish to submit this form?
          </CModalBody>
          <CModalFooter className="pl-0">
            <CButton color="secondary" onClick={() => setVisible(false)}>
              Close
            </CButton>
            <CButton
              color="primary"
              className="ms-2"
              //   onClick={() => handleAssignPoints()}
            >
              Yes!
            </CButton>
          </CModalFooter>
        </CModal>
        <div className="d-flex justify-content-between ">
          <div className=" text-center ">
            <h2>Mark Attendance For Current Lecture</h2>
          </div>
          <div className="d-flex gap-3 flex-column justify-content-end">
            <button
              className="btn btn-dark float-end m-0"
              onClick={() => {
                navigate("/teacher/attendance/today");
              }}
            >
              Mark Own Attendance
            </button>
          
          </div>
              </div>
              
              <div className="d-flex justify-content-between mt-5">
                   
           
          

        <div className="filters d-flex gap-3 pb-3 align-items-center flex-wrap">
        <DatePicker
                          selected={startDate}
                          onChange={(update) => {
                             
                            setStartDate(update);
                          }}
              isClearable={true}
              placeholderText="Select Date "
              dateFormat="dd-MM-yyyy"
            />
                 
       
            <TimePicker
              style={{ width: "200px" }}
              className="form-control "
              onChange={(value) => setStartTime(value)}
                          placeholder="Select Start Time"
                          isClearable={true}
              format="HH:mm"
            />
      
                      
            <TimePicker
              style={{ width: "200px" }}
              className="form-control "
              onChange={(value) => setEndTime(value)}
                          placeholder="Select End Time"
                          isClearable={true}
              format="HH:mm"
            />
        

          <select
            name=""
            style={{ width: "200px" }}
            className="form-control form-select"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            id=""
          >
            <option value="">Subject</option>
          </select>
              </div>
               
              <div className="d-flex flex-column gap-2">
                   <div>
                   <h5 className="ml-auto">Assistant Teacher</h5>
                  </div>
                  <div className="d-flex gap-3 flex-wrap">
                  <button
              className="btn btn-dark float-end m-0"
              onClick={() => {
                navigate("");
              }}
            >
            Teacher 1
                      </button>
                      
                      <button
              className="btn btn-dark float-end m-0"
              onClick={() => {
                navigate("");
              }}
            >
            Teacher 2
            </button>
                   </div>
               </div>

               </div>
              <Table
            rowKey="_id"
                  columns={columns}
                  width={300}
            className="bordered-table width-box"
            // dataSource={userDetails}
            pagination={false}
          />

       

      </CContainer>
    </>
  );
}

export default MarkAttendance;
