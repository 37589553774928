import React, { useEffect, useState, useRef } from "react";
import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormSelect,
  CRow,
  CContainer,
  CFormTextarea,
} from "@coreui/react";
import "../../../../TeacherAdmin/Attendance/Attendance.css"
// imports for modal
import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
} from "@coreui/react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import axios from "axios";
import { Button, Checkbox, Table, Space, notification } from "antd";

import Select from "react-select";
import { useLocation, useNavigate } from "react-router-dom";
import { useAssignContext } from "../../../../../helpers/assignContext";

function LeaveRequestSchool() {
  const navigate = useNavigate();
  const token = localStorage.getItem("accessToken");

  const { appState, setAppState } = useAssignContext();
  const [dateRange, setdateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [selectAll, setSelectAll] = useState(false);
  const [collecIds, setCollecIds] = useState([]);
  const [formData, setFormData] = useState({
    date: new Date(),
      eventName: "",
    teacherName:"",
      leaveType: "",
    Reason:"",
    remark: "",
  });

  const [validated, setValidated] = useState(false);
  const [visible, setVisible] = useState(false);
  const [userList, setUserList] = useState([]);

  const [selectedUsers, setSelectedUsers] = useState();

  const [userDetails, setUserDetails] = useState([]);


  // const handleDeleteUser = (id) => {
  //   setUserDetails((prevDetails) => prevDetails.filter((user) => user.id !== id));
  //   setSelectedUsers((prevSelected) => prevSelected.filter((user) => user.value !== id));
  // };


  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() == false) {
      setValidated(true);
    } else {
      setVisible(true);
    }
  };

 

  const userOption = userList?.map((value) => ({
    value: value?._id,
    label: `${value?.firstName} ${value?.lastName} / ${value?.itsNo} `,
  }));

  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    const updatedStudents = selectedUsers.map((student) => ({
      ...student,
      selected: !selectAll,
    }));
    setSelectedUsers(updatedStudents);
    setCollecIds(
      !selectAll ? updatedStudents.map((student) => student._id) : []
    );
  };

  const handleSelect = (id) => {
    const updatedStudents = selectedUsers.map((student) => {
      if (student._id === id) {
        student.selected = !student.selected;
        if (student.selected) {
          setCollecIds((prev) => [...prev, id]);
        } else {
          setCollecIds((prev) => prev.filter((studentId) => studentId !== id));
        }
      }
      return student;
    });
    setSelectedUsers(updatedStudents);
  };



const [eventType , setEventType] = useState('')

const [teacherName , setTeacherName] = useState('')

  const columns = [
    {
        title: 'S. No.',
        dataIndex: 'index',
        key: 'index',
        render: (text, record, index) => index + 1, 
        width: '10%',
      },
    {
      title: "Date",
      dataIndex: "name",
    },
 
    {
      title: "Time",
      dataIndex: "number",
    },
    {
      title: "Subject",
     
    },
  ];

  return (
    <>
      <CContainer fluid className="container overflow-auto">
        {/* modal */}
        <CModal
          backdrop="static"
          visible={visible}
          onClose={() => setVisible(false)}
          aria-labelledby="StaticBackdropExampleLabel"
        >
          <CModalHeader>
            <CModalTitle id="StaticBackdropExampleLabel">
              Form Submission
            </CModalTitle>
          </CModalHeader>
          <CModalBody className="pb-3">
            Are you sure you wish to submit this form?
          </CModalBody>
          <CModalFooter className="pl-0">
            <CButton color="secondary" onClick={() => setVisible(false)}>
              Close
            </CButton>
            <CButton
              color="primary"
              className="ms-2"
              // onClick={() => handleAssignPoints()}
            >
              Yes!
            </CButton>
          </CModalFooter>
        </CModal>
        <div className="d-flex justify-content-between">
                   
                  <div className=" text-center ">
                      <h2>
                      Teacher Leave Request Detail              
                        </h2>
          
        </div>
        <CButton color="primary" onClick={()=>navigate(-1)}>
              Back
            </CButton>
              </div>
        <CForm
          className=" row g-3 gap-5 needs-validation mt-5 responsive-form"
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
        >
          <CCol className="align-items-center border width-box border-2 rounded p-4">
                      <CRow>
                      <CCol >
              <label htmlFor="" className="form-label">
                {" "}
                Teacher Name <span className="red_colour">*</span>
              </label>
              <input
            className="form-control"
            type="text"
            placeholder="Teacher Name "
            value={formData?.teacherName}
            onChange={(e) => setFormData({...formData , teacherName:e.target.value})}
          />
            </CCol>
                      <CCol >
              <label htmlFor="" className="form-label">
                {" "}
                Event Name <span className="red_colour">*</span>
              </label>
              <input
            className="form-control"
            type="text"
            placeholder="Event Type "
            value={formData.eventName}
                              onChange={(e) => setFormData({...formData , eventName  :e.target.value})}
          />
            </CCol>
        </CRow>
      

            <CCol >
              <label htmlFor="" className="form-label">
                {" "}
                Select Date<span className="red_colour">*</span>
              </label>
              <DatePicker
                className="form-control"
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                  setdateRange(update);
                }}
                isClearable={true}
                placeholderText="Select Date Range"
                dateFormat="dd-MM-yyyy"
              />
            </CCol>

            <CCol >
              <label htmlFor="" className="form-label">
                {" "}
                Leave Type
              </label>
              <Select
                name="siblings"
                options={userOption}
                isSearchable={true}
                isClearable={false}
                              isMulti={true}
                              value={formData?.leaveType}
                onChange={(e) => {
                    setFormData({ ...formData, leaveType: e.target.value });
                  }}
              />
            </CCol>
            <CCol>
              <CFormTextarea
                aria-describedby="validationCustom04Feedback"
                feedbackInvalid="Please Give Remark"
                id="validationCustom04"
                label="Detail Reason"
                name="remark"
                required
                value={formData?.remark}
                onChange={(e)=>setFormData({...formData,remark : e.target.value})}
                className="transform-capital  "
                placeholder="Enter Leave Reason"
              />
            </CCol>
            <CCol>
              <CFormTextarea
                aria-describedby="validationCustom04Feedback"
                feedbackInvalid="Please Give Remark"
                id="validationCustom04"
                label="Remark"
                name="remark"
                required
                value={formData?.Reason}
                onChange={(e)=>setFormData({...formData,Reason : e.target.value})}
                className="transform-capital  "
                placeholder="Enter Remark here..."
              />
            </CCol>
          </CCol>
          <div className="d-flex flex-column width-box border gap-5  border-2 rounded p-4">
            
          <Table
          
          rowKey="_id"
          columns={columns}
          className="bordered-table"
          dataSource={userDetails}
          pagination={false}
            />
            
         
          </div>

         
        </CForm>
        <div className="buttons text-center mt-5">
            <CButton color="primary" type="submit">
              Approve
            </CButton>
          </div>
      </CContainer>
    </>
  );
}

export default LeaveRequestSchool;
