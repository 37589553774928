import React, { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../helpers/AuthContext";
import { useContext } from "react";
const AdminLoginTemplate = () => {
  const navigate = useNavigate();
  const { setAuthState } = useContext(AuthContext);

  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const togglePasswordVisibility = () => {
    setPasswordVisible((prevState) => !prevState);
  };
  useEffect(() => {
    localStorage.setItem("menuOpened", "Dashboard");
  }, []);
  const date = () => {
    return new Date().getFullYear();
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_DEV_BASE_URL}/api/v1/admin/login`,

        {
          email,
          password,
        }
      );
      console.log(response.data.data.user._id);

      setAuthState({
        role: response.data.data.user.role,
        id: response.data.data.user._id,
        name: response.data.data.user.firstName,
        lastname: response.data.data.user.lastName,
      });
      const token = response.data.token;
      const user = response.data.data.user.role;
      const userId = response.data.data.user._id;
      console.log(response);
      localStorage.setItem("accessToken", token);
      localStorage.setItem("user", user);
      localStorage.setItem("userId", userId);
      console.log("sucessfully logged in");
      // window.location.replace("/");
      navigate("*");
      setEmail("");
      setPassword("");
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.message;
        console.error("Login failed:", errorMessage);
        alert(errorMessage); // Show the error message from the backend
      } else {
        console.error("Login failed:", error.message);
        alert("An error occurred. Please try again."); // Show a generic error message
      }
    }
  };

  return (
    <div className="container-fuild">
      <div className="w-100 overflow-hidden position-relative flex-wrap d-block vh-100">
        <div className="row">
          <div className="col-lg-6 Div_admin">
            <div className="d-lg-flex align-items-center justify-content-center d-lg-block d-none flex-wrap vh-100 overflowy-auto">
              <div className="authen-overlay-item w-75 mx-auto p-4">
                {" "}
                <h1 className="Admin_color">Welcome Admin</h1>
                {/* Reduced width and centered */}
                {/* <Link to="/" className="text-decoration-none" tabIndex={-1}> */}
                {/* <Link
                  to={`/student/login/${localStorage.getItem("schoolId")}`}
                  className="text-decoration-none"
                  tabIndex={-1}
                >
                  <div className="d-flex align-items-center flex-row mb-3 justify-content-between p-3 br-5 gap-3 card shadow-sm">
                    <div className="text-center w-100">
                      <span className="font-weight-bold">Login as Student</span>
                    </div>
                    <i className="ti ti-chevrons-right" />
                  </div>
                </Link>
                <Link
                 
                  to={`/school/admin/${localStorage.getItem("schoolId")}`}
                  className="text-decoration-none"
                  tabIndex={-1}
                >
                  <div className="d-flex align-items-center flex-row mb-3 justify-content-between p-3 br-5 gap-3 card shadow-sm">
                    <div className="text-center w-100">
                      <span className="font-weight-bold">
                        Login as School Admin
                      </span>
                    </div>
                    <i className="ti ti-chevrons-right" />
                  </div>
                </Link> */}
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="row justify-content-center align-items-center vh-100 overflow-auto flex-wrap ">
              <div className="col-md-8 mx-auto p-4">
                <form onSubmit={handleLogin}>
                  <div>
                    <div className="card">
                      <div className="card-body pb-3">
                        <div className=" mb-4">
                          <h2 className="mb-2">Welcome </h2>
                          <p className="mb-0">
                            Please enter your details to sign in as Admin
                          </p>
                        </div>

                        <div className="mb-3 ">
                          <label className="form-label">Email</label>
                          <div className="input-icon mb-3 position-relative">
                            <span className="input-icon-addon">
                              <i className="ti ti-mail" />
                            </span>
                            <input
                              type="email"
                              defaultValue={email}
                              className="form-control"
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                          <label className="form-label">Password</label>
                          <div className="pass-group">
                            <input
                              type={isPasswordVisible ? "text" : "password"}
                              className="pass-input form-control"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                            <span
                              className={`ti toggle-password ${
                                isPasswordVisible ? "ti-eye" : "ti-eye-off"
                              }`}
                              onClick={togglePasswordVisibility}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="p-4 pt-0">
                        <div className="mb-3">
                          <button
                            type="submit"
                            className="btn btn-primary w-100"
                          >
                            Sign In
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLoginTemplate;
