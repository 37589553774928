import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { useDispatch } from "react-redux";
import {
  resetAllMode,
  setDataLayout,
} from "../../../data/redux/themeSettingSlice";
import { setExpandMenu } from "../../../data/redux/sidebarSlice";
import usePreviousRoute from "../.././usePreviousRoute";

import {
  IconSchool,
  IconDashboard,
  IconBrandAsana,
  IconBrandThreads,
  IconCategoryFilled,
  IconUsersGroup,
  IconZodiacAries,
  IconHomeEco,
  IconTableOptions,
  IconSitemap,
  IconBoxMultiple2,
  IconSettings,
  IconMessageUser,
  IconGift,
  IconChartBarPopular,
  IconFilePlus,
  IconHeartPlus,
} from "@tabler/icons-react";

const iconComponents = {
  IconDashboard: IconDashboard,
  IconSchool: IconSchool,
  IconMessageUser: IconMessageUser,
  IconBrandAsana: IconBrandAsana,
  IconBrandThreads: IconBrandThreads,
  IconCategoryFilled: IconCategoryFilled,
  IconTableOptions: IconTableOptions,
  IconUsersGroup: IconUsersGroup,
  IconZodiacAries: IconZodiacAries,
  IconHomeEco: IconHomeEco,
  IconSitemap: IconSitemap,
  IconBoxMultiple2: IconBoxMultiple2,
  IconSettings: IconSettings,
  IconGift: IconGift,
  IconChartBarPopular: IconChartBarPopular,
  IconFilePlus: IconFilePlus,
  IconHeartPlus: IconHeartPlus,
};

const navigationTeacher = [
  {
    title: "Dashboard",
    href: "/teacher/dashboard",
    icon: "IconDashboard",
  },

  {
    title: "Behaviour",
    icon: "IconHeartPlus",
    subMenu: [
      {
        title: "LeaderBoard",
        href: "/teacher/behaviour/leaderboard",
        icon: "IconChartBarPopular",
      },
      {
        title: "Coupons",
        href: "/teacher/coupons",
        icon: "IconGift",
      },

      {
        title: "Assign Points",
        href: "/teacher/points/assigned",
        icon: "IconFilePlus",
      },
    ],
  },

  {
    title: "Attendance",
    
    subMenu: [
      {
        title: "Leaves",
        href: "/teacher/attendance/view",
        icon: "IconChartBarPopular",
      },
      {
        title: "Attendance",
        href: "/teacher/attendance/markattendance"
       ,
        icon: "IconGift",
      },

      {
        title: "Today Attendence",
        href: "/teacher/attendance/today",
        icon: "IconFilePlus",
      },
    ],
    icon: "IconBoxMultiple2",
  },
];

const TeacherSidebar = () => {
  const Location = useLocation();

  const currentPath = Location.pathname;

  const [user, setUsers] = useState("");
  useEffect(() => {
    setUsers(localStorage.getItem("user"));
  }, []);

  const [subOpen, setSubopen] = useState("");
  const [subsidebar, setSubsidebar] = useState("");

  const toggleSidebar = (title) => {
    localStorage.setItem("menuOpened", title);
    if (title === subOpen) {
      setSubopen("");
    } else {
      setSubopen(title);
    }
  };

  const handleLayoutChange = (layout) => {
    dispatch(setDataLayout(layout));
  };

  const location = useLocation();
  const dispatch = useDispatch();
  const previousLocation = usePreviousRoute();

  useEffect(() => {
    const layoutPages = [
      "/layout-dark",
      "/layout-rtl",
      "/layout-mini",
      "/layout-box",
      "/layout-default",
    ];

    const isCurrentLayoutPage = layoutPages.some((path) =>
      location.pathname.includes(path)
    );
    const isPreviousLayoutPage =
      previousLocation &&
      layoutPages.some((path) => previousLocation.pathname.includes(path));

    if (isPreviousLayoutPage && !isCurrentLayoutPage) {
      dispatch(resetAllMode());
    }
  }, [location, previousLocation, dispatch]);

  useEffect(() => {
    setSubopen(localStorage.getItem("menuOpened"));
    // Select all 'submenu' elements
    const submenus = document.querySelectorAll(".submenu");
    // Loop through each 'submenu'
    submenus.forEach((submenu) => {
      // Find all 'li' elements within the 'submenu'
      const listItems = submenu.querySelectorAll("li");
      submenu.classList.remove("active");
      // Check if any 'li' has the 'active' class
      listItems.forEach((item) => {
        if (item.classList.contains("active")) {
          // Add 'active' class to the 'submenu'
          submenu.classList.add("active");
          return;
        }
      });
    });
  }, [Location.pathname]);

  const onMouseEnter = () => {
    dispatch(setExpandMenu(true));
  };
  const onMouseLeave = () => {
    dispatch(setExpandMenu(false));
  };

  const handleToggleRemove = () => {
    // Toggle state to either add or remove the class
    document.body.classList.remove("mini-sidebar");
  };
  const [dropdownStates, setDropdownStates] = useState({});

  const handleDropdownToggle = (index) => {
    setDropdownStates((prevState) => ({
      // Close the previously opened submenu, open the new one
      [index]: prevState[index] ? false : true,
    }));
  };

  return (
    <div
      className="sidebar"
      id="sidebar"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div
        className="sidebar"
        id="sidebar"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <Scrollbars>
          <div className="sidebar-inner slimscroll">
            <div id="sidebar-menu" className="sidebar-menu">
              <ul className="navigation-menu p-0">
                {navigationTeacher.map((navi, index) => {
                  const IconComponent = iconComponents[navi.icon]; // Get the icon component dynamically

                  return (
                    <li
                      key={index}
                      className={`menu-item d-flex ${
                        navi.subMenu ? "has-dropdown" : ""
                      } ${
                        currentPath == navi.href ||
                        (navi.subMenu &&
                          navi.subMenu.some(
                            (subItem) => currentPath === subItem.href
                          ))
                          ? "active-path"
                          : ""
                      }`}
                    >
                      {navi.subMenu ? (
                        <>
                          <a
                            onClick={() => handleDropdownToggle(index)}
                            className="dropdown-toggle"
                          >
                            <div className="iconCont">
                              {IconComponent && <IconComponent stroke={1.5} />}{" "}
                              {/* Render icon dynamically */}
                            </div>
                            <span>{navi.title}</span>
                          </a>
                          {dropdownStates[index] && (
                            <ul className="dropdown-menu">
                              {navi.subMenu.map((subItem, subIndex) => {
                                const SubIconComponent =
                                  iconComponents[subItem.icon];
                                return (
                                  <li
                                    key={subIndex}
                                    className={`${
                                      currentPath === subItem.href
                                        ? "active-path"
                                        : ""
                                    }`}
                                  >
                                    <Link
                                      to={subItem.href}
                                      onClick={() => setDropdownStates({})}
                                    >
                                      {SubIconComponent && (
                                        <SubIconComponent stroke={1.5} />
                                      )}{" "}
                                      {/* Submenu icon */}
                                      <span>{subItem.title}</span>
                                    </Link>
                                  </li>
                                );
                              })}
                            </ul>
                          )}
                        </>
                      ) : (
                        <Link to={navi.href}>
                          <div className="iconCont">
                            {IconComponent && <IconComponent stroke={1.5} />}{" "}
                            {/* Render icon dynamically */}
                          </div>
                          <span>{navi.title}</span>
                        </Link>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </Scrollbars>
      </div>
    </div>
  );
};

export default TeacherSidebar;
