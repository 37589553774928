import { Outlet, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import SchoolSidebar from "./school/SchoolSidebar";
import Header from "./Header";
import SchoolHeader from "./school/SchoolHeader";
import { Container } from "reactstrap";
import AppContent from "./appcontent/AppContent";
import { useEffect, useState } from "react";
import SchoolAppContent from "./appcontent/SchoolAppContent";
import TemplateHeader from "./school/newSchoolHeader";
import SuperAdminHeader from "./adminheader";
import TeacherSidebar from "./Teacher/Sidebar/teachersidebar";
import TeacherHeader from "./Teacher/Header/teacherHeader";
import TeacherAppContent from "./appcontent/teacherAppContent";

const FullLayout = () => {
  const navigate=useNavigate();
  useEffect(()=>{
    if(!localStorage.getItem('accessToken')){
      navigate('/');
    }
  },[])

  const [users  ,setUsers] = useState('')
  useEffect(()=>{
    localStorage.getItem("user")
    setUsers(localStorage.getItem("user"));
  },[])

  const [schooldetails , setSchoolDetails] = useState({
    schoolName:"",
    schoolLogo:"",
    fullName:""
  })
  return (
    <main>
      {/********header**********/}
      {
      localStorage.getItem('user') == "school-admin" ? <TemplateHeader schooldetails={schooldetails} />
      : localStorage.getItem('user') == "admin" ?  <SuperAdminHeader /> 
      : localStorage.getItem('user') == "teacher" ?  <TeacherHeader /> 
      :  <Header />}
      {/********Sidebar**********/}
      <aside className="sidebarArea p-0 " id="sidebarArea">
        {  localStorage.getItem('user') === "school-admin" ? <SchoolSidebar setSchoolDetails={setSchoolDetails} /> :localStorage.getItem('user') === "teacher" ?  <TeacherSidebar />  :   <Sidebar /> }
        </aside>
      {/********Main Content**********/}
      <div className="page-wrapper">
        {/********Content Area**********/}
          {/********Middle Content**********/}
          <div className="content">
            {  localStorage.getItem('user') === "school-admin" ? <SchoolAppContent /> : 
            localStorage.getItem('user') === "teacher" ? <TeacherAppContent /> :  <AppContent /> }
        </div>
      </div>
    </main>
  );
};

export default FullLayout;
