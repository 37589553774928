import React, { Suspense, useState } from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
// import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
// import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
// import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Import Bootstrap JS (including Popper.js)

import FullLayout from "./layouts/FullLayout";
import Loader from "./layouts/loader/Loader";
import { AuthContext } from "./helpers/AuthContext.js";

import ForgotPassword from "./views/ForgotPassword.js";
import ResetPassword from "./views/ResetPassword.js";
import SchoolForgotPassword from "./views/School-admin/ForgotPassword/ForgotPassword.jsx";
import SchoolResetPassword from "./views/School-admin/ResetPassword/ResetPassword.jsx";
import "./style/css/style.css";
import { Provider } from "react-redux";
import store from "./redux/store.js";
import AdminLoginTemplate from "./views/AdminLoginTemplate.js";
import SchoolAdminLoginTemp from "./views/School-admin/SchoolAdminLoginTemp.js";
// import "./index.scss"
import "./style/scss/pages/_settings.scss";
import TeacherLogin from "./views/TeacherAdmin/Login/login";
// Pages

const LoginTemplate = React.lazy(() => import("./views/Login_Template.js"));

const Register = React.lazy(() => import("./views/Register"));

const App = () => {
  const [authState, setAuthState] = useState({
    role: "",
    name: "",
    class: "",
    id: "",
    fatherName: "",
  });

  const accessToken = localStorage.getItem("accessToken");

  return (
    <Provider store={store}>
      <HashRouter>
        <Suspense fallback={<Loader />}>
          <AuthContext.Provider value={{ authState, setAuthState }}>
            <Routes>
              <Route
                path="/student/login/:schoolName/:uniqueId"
                name=""
                element={<LoginTemplate />}
              />

              <Route
                path="/forgotpassword"
                name="Forgot Password"
                element={<ForgotPassword />}
              />
              <Route
                path="/resetpassword/:token"
                name="Reset Password"
                element={<ResetPassword />}
              />

              <Route
                path="/admin"
                name="Admin Page"
                element={<AdminLoginTemplate />}
              />
              <Route
                path="/school/admin/:schoolName/:uniqueId"
                name="SchoolAdmin Page"
                element={<SchoolAdminLoginTemp />}
              />
              <Route
                path="/school/forgotpassword"
                name="SchoolAdmin Page"
                element={<SchoolForgotPassword />}
              />
              <Route
                path="/school/resetpassword/:token"
                name="SchoolAdmin Page"
                element={<SchoolResetPassword />}
              />

              <Route
                path="/teacher/login/:schoolName/:uniqueId"
                name="Teacher Login"
                element={<TeacherLogin />}
              />

              <Route
                exact
                path="/register"
                name="Register Page"
                element={<Register />}
              />

              <Route path="*" name="Home" element={<FullLayout />} />
            </Routes>
          </AuthContext.Provider>
        </Suspense>
      </HashRouter>
    </Provider>
  );
};

export default App;
