import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormFeedback,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import axios from "axios";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const studentid = localStorage.getItem("uniqueId");
  const [itsNo, setitsNo] = useState("");
  const [error, setError] = useState("");

  const isFormValid = () => {
    if (!itsNo.trim()) {
      setError("ITS Number is required");
      return false;
    }
    setError("");
    return true;
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!isFormValid()) {
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_DEV_BASE_URL}/api/v1/school/forgotPassword/${studentid}`,
        {
          itsNo,
        }
      );
      console.log(response.data.message);
      alert(response.data.message);
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.message;
        console.error("Login failed:", errorMessage);
        setError(errorMessage);
      } else {
        console.error("Login failed:", error.message);
        setError("An error occurred. Please try again.");
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const handleChange = (e) => {
    setitsNo(e.target.value);
    if (error) setError("");
  };

  const handleKeyDownIts = (e) => {
    const forbiddenKeys = ["-", "+", "."];

    if (forbiddenKeys.includes(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm onSubmit={handleLogin} onKeyDown={handleKeyDown}>
                    <h2>Forgot Password</h2>

                    <CInputGroup className="mb-3">
                      <CInputGroupText></CInputGroupText>
                      <CFormInput
                        type="number"
                        placeholder="Enter ITS Number"
                        value={itsNo}
                        onChange={(e) => {
                          const value = e.target.value;

                          if (value === "" || /^[0-9]+$/.test(value)) {
                            handleChange(e);
                          }
                        }}
                        onKeyDown={handleKeyDownIts}
                        invalid={!!error}
                      />
                      <CFormFeedback invalid>{error}</CFormFeedback>
                    </CInputGroup>

                    <CRow>
                      <CCol xs={6}>
                        <CButton color="primary" className="px-4" type="submit">
                          Submit
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
              <CCard
                className="text-white bg-primary py-5"
                style={{ width: "44%" }}
              ></CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default ForgotPassword;
