
import React, { useEffect, useState, useRef } from "react";
import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormSelect,
  CRow,
  CContainer,
  CFormTextarea,
} from "@coreui/react";
// imports for modal
import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
} from "@coreui/react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import axios from "axios";
import { Button, Checkbox, Table, Space, notification } from "antd";

import Select from "react-select";
import { useLocation, useNavigate } from "react-router-dom";
import { useAssignContext } from "../../../../helpers/assignContext";

function TodayAttendance() {
  const navigate = useNavigate();
  const token = localStorage.getItem("accessToken");

  const { appState, setAppState } = useAssignContext();
  const [dateRange, setdateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [selectAll, setSelectAll] = useState(false);
  const [collecIds, setCollecIds] = useState([]);
  const [formData, setFormData] = useState({
    date: new Date(),
    eventType: "",
    leaveType: "",
    remark: "",
  });


  const [data, setData] = useState(null);
  const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    

   
  const columns = [
    {
      title: "S. No.",
      dataIndex: "index",
          key: "index",
         
      render: (_, __, index) => index + 1, // Calculates serial number
    },
    {
      title: "Grade",
      dataIndex: "name",
      },
      {
        title: "Section",
        dataIndex: "name",
      },

      {
        title: "Lecture Time",
        dataIndex: "name",
      },
      {
        title: "Subject",
        dataIndex: "name",
      },
      {
        title: "Attendance Status",
        dataIndex: "name",
      },
      {
        title: "Check Strength",
        dataIndex: "name",
      },
      {
        title: "Present",
        dataIndex: "name",
      },
      {
        title: "Absent",
        dataIndex: "name",
      },
    {
      title: "Action",
      dataIndex: "selected",
      render : (_, record) => (
        <Space>
        {record?.status !== "pending" ? (
          <>
            <Button variant="outlined" onClick={()=>navigate("/teacher/attendance/edit")} >Edit</Button>
            <Button danger variant="outlined">Withdraw</Button>
          </>
        ) : (
          <Button   variant="outlined">View</Button>
        )}
      </Space>)
    },
  ];
  return (
    <>
      <CContainer fluid className="container overflow-auto">
        {/* modal */}
    
      
              <div>
                   <h3>Today's Attendance</h3>
        </div>
      

          <Table
            rowKey="_id"
            columns={columns}
            className="bordered-table"
            // dataSource={userDetails}
            pagination={false}
          />

       
      </CContainer>
    </>
  );
}

export default TodayAttendance;
