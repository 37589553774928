import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import user1 from "../../../assets/images/users/user4.jpg";
import { AuthContext } from "../../../helpers/AuthContext";
import { CToast, CToastHeader, CToastBody } from "@coreui/react";

import { setExpandMenu, setMobileSidebar } from "../../../redux/sidebarSlice";

import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { RxCross1 } from "react-icons/rx";

const TeacherHeader = () => {
  const dispatch = useDispatch();
  const { authState, setAuthState } = useContext(AuthContext);
  const navigate = useNavigate();
  const dataLayout = useSelector((state) => state.themeSetting.dataLayout);
  const [notificationVisible, setNotificationVisible] = useState(false);
  const [father, setfather] = useState("");
  const [photo, setPhoto] = useState(null);

  const [token] = useState(localStorage.getItem("accessToken"));
  const [studentName, setStudentName] = useState(authState.name);
  const [SchoolName, setSchoolName] = useState("");

  const [SchoolLogo, setSchoolLogo] = useState("");
  const [siblings, setSiblings] = useState([]);
  const [toast, addToast] = useState(0);
  const user = localStorage.getItem("user");

  const [isOpen, setIsOpen] = useState(false);
  const toggleDropDown = () => {
    setIsOpen(!isOpen);
  };

  const exampleErrorToast = (message, heading) => (
    <CToast>
      <CToastHeader closeButton>
        <svg
          className="rounded me-2"
          width="20"
          height="20"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="xMidYMid slice"
          focusable="false"
          role="img"
        >
          <rect width="100%" height="100%" fill="#007aff"></rect>
        </svg>
        <div className="fw-bold me-auto">{heading}!</div>
      </CToastHeader>
      <CToastBody>{message}</CToastBody>
    </CToast>
  );

  const changepassword = () => {
    navigate("/change-password");
  };

  const logout = async () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("user");
    // navigate("/");
    navigate(
      `/teacher/login/${localStorage.getItem(
        "schoolName"
      )}/${localStorage.getItem("uniqueId")}`
    );
  };

  const mobileSidebar = useSelector(
    (state) => state.sidebarSlice.mobileSidebar
  );

  useEffect(() => {
    const userInhead = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_DEV_BASE_URL}/api/v1/school/me`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setStudentName(
          `${res.data.data?.firstName} ${res.data.data?.lastName}`
        );
        setSchoolName(res.data.data?.schoolName);
        setSchoolLogo(res.data.data?.schoolLogo);
        setSiblings(res.data.data.siblings?.members);

        if (res.data.data?.photo) {
          setPhoto(
            `${process.env?.REACT_APP_DEV_BASE_URL}/uploads/${res.data.data?.photo}`
          );
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
    userInhead();
  }, [authState.id, authState.name]);

  const toggleMobileSidebar = () => {
    dispatch(setMobileSidebar(!mobileSidebar));
  };

  const onMouseEnter = () => {
    dispatch(setExpandMenu(true));
  };
  const onMouseLeave = () => {
    dispatch(setExpandMenu(false));
  };
  // const handleToggleMiniSidebar = () => {
  //   if (dataLayout === "mini_layout") {
  //     dispatch(setDataLayout("default_layout"));
  //     localStorage.setItem("dataLayout", "default_layout");
  //   } else {
  //     dispatch(toggleMiniSidebar());
  //   }
  // };
  const handleToggleMiniSidebar = () => {
    document.body.classList.toggle("mini-sidebar");
  };

  return (
    <>
      {/* Header */}
      <div className="header">
        {/* Logo */}
        <div
          className="header-left active"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <Link className="logo logo-normal">
            <img
              src={SchoolLogo ? SchoolLogo : "assets/img/logo.svg"}
              alt="Logo"
              className="school_logo"
            />
          </Link>
          <Link className="logo-small">
            <img
              src={SchoolLogo ? SchoolLogo : "assets/img/logo-small.svg"}
              alt="Logo"
              className="school_logo"
            />
          </Link>
          <Link className="dark-logo">
            <img src="assets/img/logo.svg" alt="Logo" />
          </Link>

          <Link id="toggle_btn" to="#" onClick={handleToggleMiniSidebar}>
            <i className="ti ti-menu-deep" />
          </Link>
        </div>
        {/* /Logo */}
        {document.body.classList.contains("mini-sidebar") ? (
          <Link
            id="mobile_btn"
            className="mobile_btn"
            to="#sidebar"
            onClick={handleToggleMiniSidebar}
          >
            <RxCross1 />
          </Link>
        ) : (
          <Link
            id="mobile_btn"
            className="mobile_btn"
            to="#sidebar"
            onClick={handleToggleMiniSidebar}
          >
            <span className="bar-icon">
              <span />
              <span />
              <span />
            </span>
          </Link>
        )}
        <div className="header-user">
          <div className="nav user-menu">
            {/* Search */}

            <div className="nav-item nav-search-inputs me-auto">
              <span class="p-0 d-none d-lg-flex  align-items-center bg-white rounded p-2 text-dark fw-bold fs-4">
                Welcome to {SchoolName}
              </span>
            </div>

            {/* /Search */}
            <div className="d-flex align-items-center" style={{ zIndex: 1 }}>
              <div className="dropdown ms-1 d-flex align-items-center">
                <h6 className="  d-none d-lg-inline text-gray-800 pr-3">
                  Teacher Admin <span className="text-info">{studentName}</span>
                </h6>
                <Link
                  to="#"
                  className="dropdown-toggle d-flex flex-column align-items-center"
                  data-bs-toggle="dropdown"
                >
                  <span className="avatar avatar-md">
                    <img
                      src={photo ? photo : user1}
                      alt="Img"
                      className="img-fluid rounded"
                    />
                  </span>
                </Link>

                <div className="dropdown-menu">
                  <div className="d-block">
                    <div className="d-flex align-items-center p-2">
                      <span className="avatar avatar-md me-2 online avatar-rounded">
                        <img
                          src={photo ? photo : user1}
                          alt="img"
                          className="img-fluid rounded-circle"
                        />
                      </span>
                      <div>
                        <h6>{studentName}</h6>

                        <h6> Teacher Admin </h6>
                      </div>
                    </div>
                    <hr className="m-0" />
                    <button
                      className="dropdown-item d-inline-flex align-items-center p-2"
                      onClick={() => navigate("/teacher/profile/edit")}
                    >
                      <i className="ti ti-pencil me-2" />
                      Edit Profile
                    </button>

                    <hr className="m-0" />
                    <button
                      className="dropdown-item d-inline-flex align-items-center p-2"
                      onClick={() => navigate("/changepassword")}
                    >
                      <i className="ti ti-user-circle me-2" />
                      Change Password
                    </button>

                    <hr className="m-0" />
                    <button
                      className="dropdown-item d-inline-flex align-items-center p-2"
                      onClick={logout}
                    >
                      <i className="ti ti-login me-2" />
                      Logout
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeacherHeader;
